import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { ModalService } from './modal.service';
import { ToastService } from '@common/services/toast.service';

@Injectable({
  providedIn: 'root',
})
export class IdelTimeoutService {
  private idleTimeout: any;
  private logoutTimeout: any;
  private readonly timeoutDuration = 60 * 60 * 1000; // 1 hour
  private readonly warningDuration = 5 * 60 * 1000; // 5 minutes
  private modalRef: any; // Reference to the active modal
  private eventListeners: string[] = ['mousemove', 'keydown', 'click', 'scroll']; // Events to track
  private isLoggedOut = false; // Prevent multiple logout calls
  private countdownInterval: any; // For the countdown timer
  private countdown: number = this.warningDuration / 1000; // Countdown in seconds

  constructor(
    private modalService: ModalService,
    private router: Router,
    private ngZone: NgZone,
    private toastService: ToastService
  ) {}

  startTracking(): void {
    this.resetTimer();

    // Add event listeners for user activity
    this.eventListeners.forEach(event => {
      window.addEventListener(event, this.resetTimer.bind(this));
    });
  }

  resetTimer(): void {
    if (this.isLoggedOut) return; // Don't reset the timer if the user is logged out

    clearTimeout(this.idleTimeout);
    clearTimeout(this.logoutTimeout); // Clear auto-logout timer when activity is detected
    clearInterval(this.countdownInterval);

    // Set timer for inactivity
    this.idleTimeout = setTimeout(() => this.showWarning(), this.timeoutDuration - this.warningDuration);
  }

  private async showWarning(): Promise<void> {
    if (this.isLoggedOut) return; // Don't show warning if the user is logged out
    
    const title = 'Session Expire Warning';
    const message =
      'Your session will expire in 5:00 minutes. Do you want to extend the session?';
  
    // Reset countdown
    this.countdown = this.warningDuration / 1000;
    this.modalRef = this.modalService.openInactivityAlert(
      title,
      message,
      this.formatCountdown(this.countdown) // Pass formatted countdown initially
    );
  
    // Ensure modalRef is initialized
    console.log('ModalRef initialized:', this.modalRef);
  
    // Recursively call setTimeout to update countdown
    const updateCountdown = () => {
      if (this.countdown <= 0) {
        this.logoutUser(); // Log out when countdown hits 0
        return;
      }
  
      // Decrement countdown
      this.countdown--;

        // Show the countdown only when there are 5 seconds or less
    if (this.countdown <= 300) {
      let updatedMessage;
      if(this.countdown <= 60){
         updatedMessage = `Your session will expire in ${this.formatCountdown(this.countdown)} seconds. Do you want to extend the session?`;
      }else{
        updatedMessage = `Your session will expire in ${this.formatCountdown(this.countdown)} minutes. Do you want to extend the session?`;
      }
      //const updatedMessage = `Auto logout in ${this.formatCountdown(this.countdown)}`;
      

      // Update the modal's countdown value and change message for the last 5 seconds
      if (this.modalRef) {
        this.modalRef.componentInstance.countdown = this.formatCountdown(this.countdown);
        //this.modalRef.componentInstance.autoLogoutMessage = updatedMessage; // Update message in modal
        this.modalRef.componentInstance.message = updatedMessage; // Update message in modal
      }
    } else {
      // Update the countdown without changing the message
      if (this.modalRef) {
        this.modalRef.componentInstance.countdown = this.formatCountdown(this.countdown);
      }
    }
    
  
      // Update the modal's countdown value dynamically
      if (this.modalRef) {
        this.modalRef.componentInstance.countdown = this.formatCountdown(this.countdown);
       
      }
  
      // Recursively call every second
      setTimeout(updateCountdown, 1000); 
    };
  
    // Start the countdown
    updateCountdown();
  
    // Start auto-logout timer
    this.logoutTimeout = setTimeout(() => this.logoutUser(), this.warningDuration);
  
    try {
      const result = await this.modalRef; // Wait for user response
      clearTimeout(this.logoutTimeout); // Cancel auto-logout if user confirms
      if (result === 'confirmed') {
        this.resetTimer(); // Reset the timer if user stays logged in
      }
    } catch {
      this.logoutUser(); // Modal dismissed without interaction
    }
  }
  
  

  logoutAutoUser(): void {
    console.log('LogoutAutoUser called'); // Debugging line
    this.logoutFromButton();
  }

  private  logoutFromButton(){
    if (this.isLoggedOut) return; // Prevent multiple logout calls
    this.isLoggedOut = true;

    // Clear all timers
    clearTimeout(this.idleTimeout);
    clearTimeout(this.logoutTimeout);
    clearInterval(this.countdownInterval);

    // Remove event listeners
    this.eventListeners.forEach(event => {
      window.removeEventListener(event, this.resetTimer.bind(this));
    });

    // Close the modal if it's still open
    if (this.modalRef) {
      this.modalRef.dismiss(); // Close modal explicitly
      this.modalRef = null; // Clear the reference
    }

    // Perform logout actions
    this.ngZone.run(() => {
      this.toastService.clearAll();
      localStorage.clear();
      sessionStorage.clear();
      this.router.navigate(['/auth/login']); // Redirect to login page
    });
  }

  private async logoutUser(): Promise<void> {
    if (this.isLoggedOut) return; // Prevent multiple logout calls
    this.isLoggedOut = true;

    // Clear all timers
    clearTimeout(this.idleTimeout);
    clearTimeout(this.logoutTimeout);
    clearInterval(this.countdownInterval);

    // Remove event listeners
    this.eventListeners.forEach(event => {
      window.removeEventListener(event, this.resetTimer.bind(this));
    });

    // Close the modal if it's still open
    if (this.modalRef) {
      this.modalRef.dismiss(); // Close modal explicitly
      this.modalRef = null; // Clear the reference
    }

    //await this.showLogoutPopup();

    // Perform logout actions
    this.ngZone.run(() => {
      this.toastService.clearAll();
      localStorage.clear();
      sessionStorage.clear();
      this.router.navigate(['/auth/login']).then(() => {
        this.showLogoutPopup(); // Show the popup after navigation
      });
    });
  }

  // Show a new modal after auto logout
private async showLogoutPopup(): Promise<void> {
  const title = 'Session Timeout';
  const message = 'You have been logged out due to inactivity. Please log in again to continue.';

  // Show a modal to notify the user
  const logoutModalRef = this.modalService.openLogoutAlert(title, message);

  // Wait for the modal to be closed
  try {
    await logoutModalRef; // Wait for user interaction if needed
  } catch (error) {
    console.error('Error in logout modal:', error);
  } finally {
    // After the modal closes, proceed with the redirection
    this.router.navigate(['/auth/login']);
  }
}

  private formatCountdown(seconds: number): string {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
  }
}
