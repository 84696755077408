import { Component, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-logout-alert',
  templateUrl: './logout-alert.component.html',
})
export class LogoutAlertComponent {
  @Input()
  title!: string;
  @Input()
  message!: string;

  constructor(private modalService: NgbModal) {}

  closeModal() {
    this.modalService.dismissAll(); // Close the modal
  }
}
