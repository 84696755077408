import { Injectable } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { InactivityAlertComponent } from '@app/components/inactivity-alert/inactivity-alert.component';
import { LogoutAlertComponent } from '@app/components/logout-alert/logout-alert.component';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  constructor(private modalService: NgbModal) {}

  openLogoutAlert(title: string, message: string): Promise<any> {
    const modalRef = this.modalService.open(LogoutAlertComponent ,  {
      backdrop: 'static', 
      keyboard: false 
    }); 
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.message = message;
    return modalRef.result; // Return the result of the modal (can be resolved when closed)
  }

  openInactivityAlert(title: string, message: string , countdown: any): NgbModalRef {
    const modalRef = this.modalService.open(InactivityAlertComponent, {
      backdrop: 'static', 
      keyboard: false 
    });

    modalRef.componentInstance.title = title;
    modalRef.componentInstance.message = message;
    modalRef.componentInstance.countdown = countdown;

    return modalRef; // Return modal reference for further control
  }
}
