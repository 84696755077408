import { Component, ElementRef, EventEmitter, OnInit, ViewChild } from "@angular/core";
import { FormioRefreshValue } from "@formio/angular";
import { Formio } from 'formiojs';
import { PrismService } from "../../services/prism.service";

@Component({
  selector: 'sbpro-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.scss']
})
export class TestComponent implements OnInit {

  @ViewChild('json', {static: true}) jsonElement?: ElementRef;
  @ViewChild('code', {static: true}) codeElement?: ElementRef;
  public form: Object;
  public refreshForm: EventEmitter<FormioRefreshValue> = new EventEmitter();


  public builderOptions = {
    builder: {
      basic: false,
      data: false,
      premium: false,
      customBasic: {
        title: 'Basic Components',
        default: true,
        weight: 0,
        components: {
          textfield: true,
          number: true,
          datetime: true,
          select:true
        }
      },
      advanced: false,
      layout: false
    },
  };

  constructor(public prism: PrismService) {
    this.form = {components: []};
  }


  onChange(event:any) {
    if (this.jsonElement) {
      this.jsonElement.nativeElement.innerHTML = '';
      this.jsonElement.nativeElement.appendChild(document.createTextNode(JSON.stringify(event.form, null, 4)));
    }
    this.refreshForm.emit({
      property: 'form',
      value: event.form
    });

      setTimeout(() => {
       const result =  this.readJsonContent()
       console.log(result , 'result')
      }, 2000)

  }

  readJsonContent(): string | null {
    if (this.jsonElement) {
      return this.jsonElement.nativeElement.innerText || null; // Get the text content of the JSON element
    }
    return null; // Return null if jsonElement is not available
  }

  ngOnInit(): void {
    
  }

  ngAfterViewInit() {
    this.prism.init();
  }
}
