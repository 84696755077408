import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppCommonModule } from '@common/app-common.module';
import { NavigationModule } from '@modules/navigation/navigation.module';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgxGoogleAnalyticsModule } from 'ngx-google-analytics';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { CommonModule } from '@angular/common';
import { InactivityAlertComponent } from './components/inactivity-alert/inactivity-alert.component';
import { LogoutAlertComponent } from './components/logout-alert/logout-alert.component';
import { FormioModule } from '@formio/angular';
import { TestComponent } from './components/test/test.component';
import { PrismService } from './services/prism.service';
import { MeteringSectionFormBuilderComponent } from './components/metering-section-form-builder/metering-section-form-builder.component';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';




@NgModule({
    declarations: [AppComponent, InactivityAlertComponent, LogoutAlertComponent, TestComponent, MeteringSectionFormBuilderComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        CommonModule,
        HttpClientModule,
        FormioModule,
        NgbTooltipModule,
        AppCommonModule.forRoot(),
        NavigationModule.forRoot(),
        NgxGoogleAnalyticsModule.forRoot('G-VPPEHKT6LC'),
        GoogleTagManagerModule.forRoot({
            id: 'G-VPPEHKT6LC',
        }),
    ],
    providers: [{provide: 'googleTagManagerId',  useValue: 'G-VPPEHKT6LC'},PrismService],
    bootstrap: [AppComponent],
})
export class AppModule {}
