import {
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  ViewChild,
} from "@angular/core";
import { FormioRefreshValue } from "@formio/angular";
import { PrismService } from "../../services/prism.service";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { ApiRequestService } from "@common/services/api-request.service";
import { ToastService } from "@common/services/toast.service";
import { Router } from "@angular/router";


@Component({
  selector: "sbpro-metering-section-form-builder",
  templateUrl: "./metering-section-form-builder.component.html",
  styleUrls: ["./metering-section-form-builder.component.scss"],
})
export class MeteringSectionFormBuilderComponent implements OnInit {
  @ViewChild("json", { static: true }) jsonElement?: ElementRef;
  @ViewChild("code", { static: true }) codeElement?: ElementRef;
  public form: Object;
  public refreshForm: EventEmitter<FormioRefreshValue> = new EventEmitter();

  public builderOptions = {
    builder: {
      basic: false,
      data: false,
      premium: false,
      customBasic: {
        title: "Basic Components",
        default: true,
        weight: 0,
        components: {
          textfield: true,
          number: true,
          datetime: true,
          select: true,
          button: false,
        },
      },
      advanced: false,
      layout: false,
    },
  };
  meteringSectionData: any;
  meteringSectionName: any;
  meteringSectionID: any;

  constructor(public prism: PrismService ,  private ngxService: NgxUiLoaderService, private apiRequest: ApiRequestService , private toastService: ToastService, private router: Router) {
    this.form = { components: [] };
  }

  onChange(event: any) {
    console.log(event , 'event')
    console.log(event.form);
    if (event.form) {
      localStorage.setItem("formBuilderComponent", JSON.stringify(event.form));

      setTimeout(() => {
        if (this.jsonElement) {
          this.jsonElement.nativeElement.innerHTML = "";
          this.jsonElement.nativeElement.appendChild(
            document.createTextNode(JSON.stringify(event.form, null, 4))
          );
        }
      }, 0);

      this.refreshForm.emit({
        property: "form",
        value: event.form,
      });
    } else {
      console.error("Form upodate event is invalid", event);
    }

    setTimeout(() => {
      const result = this.readJsonContent();
      console.log(result, "result");
    }, 2000);
  }

  readJsonContent(): string | null {
    if (this.jsonElement) {
      return this.jsonElement.nativeElement.innerText || null; // Get the text content of the JSON element
    }
    return null; // Return null if jsonElement is not available
  }

  ngOnInit(): void {
    this.meteringSectionData = localStorage.getItem(
      "meteringSectionFormsCongurationId"
    );
    this.meteringSectionName = JSON.parse(
      this.meteringSectionData
    ).meteringSectionName;

    this.meteringSectionID = JSON.parse(
      this.meteringSectionData
    ).meteringSectionID;

    this.getMeteringSectionDetail()

    // const formData = localStorage.getItem("formBuilderComponent");
    // this.form = formData ? JSON.parse(formData) : { components: [] };

    console.log(this.jsonElement, "jsonElement");

   
  }

  ngAfterViewInit() {
    this.prism.init();
  }

  complete() {
    let formData: any = localStorage.getItem("formBuilderComponent");
    console.log(formData , 'form data')
    if(formData != null && formData != 'null' && formData != undefined){console.log('up')
      formData = JSON.parse(formData);
      let body = {
        payload: {
          userID: parseInt(localStorage.getItem("userID") as string),
          meteringSectionID:this.meteringSectionID,
          customFormConfig:{
            display: 'form',
            components:formData.components
          }
        },
      };
  
      this.addEditForm(body)
      console.log(body, "body");
      console.log(JSON.stringify(body))
    }else{ console.log('down')
      const defaultOptions = {
        autohide: true,
        delay: 5000,
      };

      this.toastService.show(
       "Custom Fields/Forms",
       " Please create atleast one form component  . \n",
        { headerClasses: "bg-warning text-light", ...defaultOptions }
      );
    }
  }


  remove(){
    let body = {
      payload: {
        meteringSectionID:this.meteringSectionID
      },
    };

    this.ngxService.start();
    setTimeout(() => {
      this.ngxService.stop(); 
    }, 2000);

    this.apiRequest
      .apiRequest("metering/delete_metering_section_form_configuration", "delete_metering_section_form_configuration.json", body)
      .subscribe(
        (res) => {
          console.log(res ,'delete response')
          if (res.success) {
            const defaultOptions = {
              autohide: true,
              delay: 5000,
            };

            this.toastService.show(
              "Custom Fields/Forms",
              res.message + ". \n",
              { headerClasses: "bg-success text-light", ...defaultOptions }
            );
            this.getMeteringSectionDetail()
          }
        },
        (error: any) => {
          const defaultOptions = {
            autohide: true,
            delay: 5000,
          };

          let message = "";
          for (var i = 0; i < error.error.data.length; i++) {
            message += error.error.data[i] + "\n";
          }
          this.toastService.show("Custom Fields/Forms", message, {
            headerClasses: "bg-danger text-light",
            ...defaultOptions,
          });
        }
      );

      this.getMeteringSectionDetail()

  }



  ngOnDestroy(){
    localStorage.removeItem('formBuilderComponent')
  }

  


  addEditForm(body:any) {
    this.ngxService.start();
    setTimeout(() => {
      this.ngxService.stop(); 
    }, 2000);

    this.apiRequest
      .apiRequest("metering/add_metering_section_form_configuration", "add_metering_section_form_configuration.json", body)
      .subscribe(
        (res) => {
          console.log(res , ' yo yo yo')
          if (res.success) {
            const defaultOptions = {
              autohide: true,
              delay: 5000,
            };

            this.toastService.show(
              "Custom Fields/Forms",
              res.message + ". \n",
              { headerClasses: "bg-success text-light", ...defaultOptions }
            );
            this.getMeteringSectionDetail()
          }
        },
        (error: any) => {
          const defaultOptions = {
            autohide: true,
            delay: 5000,
          };

          let message = "";
          for (var i = 0; i < error.error.data.length; i++) {
            message += error.error.data[i] + "\n";
          }
          this.toastService.show("Custom Fields/Forms", message, {
            headerClasses: "bg-danger text-light",
            ...defaultOptions,
          });
        }
      );
  }

  getMeteringSectionDetail() {
    this.ngxService.start();
    setTimeout(() => {
      this.ngxService.stop(); 
    }, 2000);

    let body = {
      payload: {
        meteringSectionID:this.meteringSectionID
      },
    };


    this.apiRequest
      .apiRequest("/metering/metering_section_details", "add_metering_section_form_configuration.json", body)
      .subscribe(
        (res) => {
          
          if (res.success) {
            // console.log(res.data.customFormConfig , ' yo yo yo')
            // console.log(res.data.customFormConfig.length , ' yo length')
            //this.form = res.data.customFormConfig 
             const formData = res.data.customFormConfig 
             console.log(formData , 'tokyo')
            // if(formData){
              localStorage.setItem('formBuilderComponent',formData )
           //  }
             this.form = formData ? JSON.parse(formData) : { components: [] };
          }
        },
        (error: any) => {
          const defaultOptions = {
            autohide: true,
            delay: 5000,
          };

          let message = "";
          for (var i = 0; i < error.error.data.length; i++) {
            message += error.error.data[i] + "\n";
          }
          this.toastService.show("Custom Fields/Forms", message, {
            headerClasses: "bg-danger text-light",
            ...defaultOptions,
          });
        }
      );
  }

  returnTolisting(){
    this.router.navigate(['/dashboard/metering/formsConfiguration'])
  }

}
