// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

//testing: https://testing-dot-smart-view-utilities-18c15.ey.r.appspot.com/
//prod: https://utilities.smartviewtechnology.co.za/mobileApp/

export const environment = {
  production: true,
  apiUrl:
    "https://smart-view-ums-api-prod-426000542377.europe-west3.run.app/",
  companyCheck: "landis",
  firebaseConfig: {
    apiKey: "AIzaSyAODXMod-bKCpQaXa0pbK_MtrTyVDqnOTA",
    authDomain: "smart-view-landis-gyr.firebaseapp.com",
    projectId: "smart-view-landis-gyr",
    storageBucket: "smart-view-landis-gyr.appspot.com",
    messagingSenderId: "426000542377",
    appId: "1:426000542377:web:1eb08a9dcd21f263f2d7b8",
  },
  GoogleLoginProvider: "426000542377-4qrra8cmanhb8hcr23god1hdeecrfg2k.apps.googleusercontent.com",
  FacebookLoginProvider: "2378914002500666"
};

export const environmentStage = {
  production: false,
  apiUrl:
    "https://smart-view-ums-api-test-426000542377.europe-west3.run.app/",
  companyCheck: "landis",
  firebaseConfig: {
    apiKey: "AIzaSyAODXMod-bKCpQaXa0pbK_MtrTyVDqnOTA",
    authDomain: "smart-view-landis-gyr.firebaseapp.com",
    projectId: "smart-view-landis-gyr",
    storageBucket: "smart-view-landis-gyr.appspot.com",
    messagingSenderId: "426000542377",
    appId: "1:426000542377:web:1eb08a9dcd21f263f2d7b8",
  },
  GoogleLoginProvider: "426000542377-4qrra8cmanhb8hcr23god1hdeecrfg2k.apps.googleusercontent.com",
  FacebookLoginProvider: "2378914002500666"
};

export const environmentTesting = {
  production: false,
  apiUrl:
    "https://smart-view-ums-api-test-426000542377.europe-west3.run.app/",
  companyCheck: "landis",
  firebaseConfig: {
    apiKey: "AIzaSyAODXMod-bKCpQaXa0pbK_MtrTyVDqnOTA",
    authDomain: "smart-view-landis-gyr.firebaseapp.com",
    projectId: "smart-view-landis-gyr",
    storageBucket: "smart-view-landis-gyr.appspot.com",
    messagingSenderId: "426000542377",
    appId: "1:426000542377:web:1eb08a9dcd21f263f2d7b8",
  },
  GoogleLoginProvider: "426000542377-4qrra8cmanhb8hcr23god1hdeecrfg2k.apps.googleusercontent.com",
  FacebookLoginProvider: "2378914002500666"
};

export const environmentDev = {
  production: false,
  apiUrl:
    "https://smart-view-ums-api-test-426000542377.europe-west3.run.app/",
  companyCheck: "landis",
  firebaseConfig: {
    apiKey: "AIzaSyAODXMod-bKCpQaXa0pbK_MtrTyVDqnOTA",
    authDomain: "smart-view-landis-gyr.firebaseapp.com",
    projectId: "smart-view-landis-gyr",
    storageBucket: "smart-view-landis-gyr.appspot.com",
    messagingSenderId: "426000542377",
    appId: "1:426000542377:web:1eb08a9dcd21f263f2d7b8",
  },
  GoogleLoginProvider: "426000542377-4qrra8cmanhb8hcr23god1hdeecrfg2k.apps.googleusercontent.com",
  FacebookLoginProvider: "2378914002500666"
};

export const environmentDemo = {
  production: false,
  apiUrl: "assets/utilitiesdemo/",
  companyCheck: "landis",
  firebaseConfig: {
    apiKey: "AIzaSyAODXMod-bKCpQaXa0pbK_MtrTyVDqnOTA",
    authDomain: "smart-view-landis-gyr.firebaseapp.com",
    projectId: "smart-view-landis-gyr",
    storageBucket: "smart-view-landis-gyr.appspot.com",
    messagingSenderId: "426000542377",
    appId: "1:426000542377:web:1eb08a9dcd21f263f2d7b8",
  },
  GoogleLoginProvider: "426000542377-4qrra8cmanhb8hcr23god1hdeecrfg2k.apps.googleusercontent.com",
  FacebookLoginProvider: "2378914002500666"
};

export const environmentLocal = {
  production: false,
  apiUrl: "http://127.0.0.1:8000/",
  companyCheck: "landis",
  firebaseConfig: {
    apiKey: "AIzaSyAODXMod-bKCpQaXa0pbK_MtrTyVDqnOTA",
    authDomain: "smart-view-landis-gyr.firebaseapp.com",
    projectId: "smart-view-landis-gyr",
    storageBucket: "smart-view-landis-gyr.appspot.com",
    messagingSenderId: "426000542377",
    appId: "1:426000542377:web:1eb08a9dcd21f263f2d7b8",
  },
  GoogleLoginProvider: "426000542377-4qrra8cmanhb8hcr23god1hdeecrfg2k.apps.googleusercontent.com",
  FacebookLoginProvider: "2378914002500666"
};

export const environmentMigration = {
  production: false,
  apiUrl:
    "https://smart-view-ums-api-test-426000542377.europe-west3.run.app/",
  companyCheck: "landis",
  firebaseConfig: {
    apiKey: "AIzaSyAODXMod-bKCpQaXa0pbK_MtrTyVDqnOTA",
    authDomain: "smart-view-landis-gyr.firebaseapp.com",
    projectId: "smart-view-landis-gyr",
    storageBucket: "smart-view-landis-gyr.appspot.com",
    messagingSenderId: "426000542377",
    appId: "1:426000542377:web:1eb08a9dcd21f263f2d7b8",
  },
  GoogleLoginProvider: "426000542377-4qrra8cmanhb8hcr23god1hdeecrfg2k.apps.googleusercontent.com",
  FacebookLoginProvider: "2378914002500666"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
