import { Injectable } from "@angular/core";
import { v4 as uuid } from "uuid";
import {
  environment,
  environmentTesting,
  environmentDev,
  environmentMigration,
  environmentDemo,
} from "../../../environments/environment";
/* import { environment,environmentDev } from "../../../environments/whitelabeled/landis/environment"; */
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, BehaviorSubject, of, throwError } from "rxjs";
import { catchError, concatMap, delay, retryWhen, takeUntil, tap } from "rxjs/operators";
import { Subject } from "rxjs";

import { Router } from "@angular/router";

@Injectable()
export class ApiRequestService {
  urlEnvironment = "";
  company = '';
  private cancelRequest$: Subject<void> = new Subject<void>();
  private readonly maxRetries = 5; // Maximum number of retries

  constructor(private http: HttpClient, private router: Router) {
    this.company = environment.companyCheck;
    localStorage.setItem("company", this.company)
  }

  apiRequest(url: string, jsonFile: string, body: any): Observable<any> {
    if (!localStorage.getItem('userID') && url.indexOf('auth') < 0) {
      this.router.navigateByUrl('/auth/login');
      return throwError('User not authenticated');
    }

    this.setEnvironment();

    const accessToken = localStorage.getItem('access_token');
    const headers = {
      'content-type': 'application/json',
      Authorization: 'Bearer ' + accessToken,
    };

    if (localStorage.getItem('environment') == 'demo') {
      return this.http
        .get(this.urlEnvironment + url + jsonFile, { headers })
        .pipe(
          retryWhen(this.retryStrategy()),
          catchError((error) => this.handleError(error))
        );
    } else {
      return this.http
        .post(this.urlEnvironment + url, body, {
          headers: headers,
          observe: 'body',
        })
        .pipe(
          retryWhen(this.retryStrategy()),
          catchError((error) => this.handleError(error))
        );
    }
  }

  private setEnvironment(): void {
    this.urlEnvironment = environment.apiUrl;
    
    // Environment overwrite
    localStorage.setItem('environment', 'prod');

    const env = localStorage.getItem('environment');
    if (env === 'testing') {
      this.urlEnvironment = environmentTesting.apiUrl;
    } else if (env === 'dev') {
      this.urlEnvironment = environmentDev.apiUrl;
    } else if (env === 'demo') {
      this.urlEnvironment = environmentDemo.apiUrl;
    } else if (env === 'migration') {
      this.urlEnvironment = environmentMigration.apiUrl;
    }
  }

  private retryStrategy() {
    return (errors: Observable<any>) => {
      return errors.pipe(
        concatMap((error, i) => {
          // Retry for CORS or other transient errors up to maxRetries times
          if (i < this.maxRetries && this.isRetryableError(error)) {
            return of(error).pipe(delay(2000));
          }
          return throwError(error);
        })
      );
    };
  }

  private isRetryableError(error: any): boolean {
    // Check if error is CORS-related or network error
    return (
      error.status === 0 || // Network error (CORS or connectivity issues)
      error.name === 'HttpErrorResponse' && error.message.includes('CORS')
    );
  }

  private handleError(error: any): Observable<any> {
    console.error('API request failed:', error);
    return throwError(error);
  }

  //APi Connection
  // apiRequest(url: string, jsonFile: string, body: any): Observable<any> {
  //   if (!localStorage.getItem("userID") && url.indexOf("auth") < 0) {
  //     this.router.navigateByUrl("/auth/login");
  //   }
  //   this.urlEnvironment = environment.apiUrl;

  //   //environment overwrite
  //   localStorage.setItem("environment", "dev");

  //   if (localStorage.getItem("environment") == "testing") {
  //     this.urlEnvironment = environmentTesting.apiUrl;
  //   } else if (localStorage.getItem("environment") == "dev") {
  //     this.urlEnvironment = environmentDev.apiUrl;
  //   } else if (localStorage.getItem("environment") == "demo") {
  //     this.urlEnvironment = environmentDemo.apiUrl;
  //   } else if (localStorage.getItem("environment") == "migration") {
  //     this.urlEnvironment = environmentMigration.apiUrl;
  //   }

  //   let accessToken = localStorage.getItem("access_token");

  //   const headers = {
  //     "content-type": "application/json",
  //     Authorization: "Bearer " + accessToken,
  //   };

  //   //demo data fixed
  //   if (localStorage.getItem("environment") == "demo") {
  //     return this.http.get(this.urlEnvironment + url + jsonFile, body);
  //   } else {
  //     //lets return live data
  //     let postData = this.http
  //       .post(this.urlEnvironment + url, body, {
  //         headers: headers,
  //         observe: "body",
  //       })
  //       .pipe(takeUntil(this.cancelRequest$));

  //     return postData;
  //   }
  // }

  cancelPendingRequests(): void {
    this.cancelRequest$.next();
    this.cancelRequest$.complete(); // Clean up the Subject
  }

  apiRequestDemo(url: string, jsonFile: string, body: any): Observable<any> {
    if (!localStorage.getItem("userID")) {
      this.router.navigateByUrl("/auth/login");
    }
    this.urlEnvironment = environment.apiUrl;

    this.urlEnvironment = environmentDemo.apiUrl;

    let accessToken = localStorage.getItem("access_token");

    const headers = {
      "content-type": "application/json",
      Authorization: "Bearer " + accessToken,
    };

    //demo data fixed
    return this.http.get(this.urlEnvironment + url + jsonFile, body);
  }

  apiRequestChatGPT(url: string, jsonFile: string, body: any): Observable<any> {
    if (!localStorage.getItem("userID") && url.indexOf("auth") < 0) {
      this.router.navigateByUrl("/auth/login");
    }

    let accessToken = "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";

    const headers = {
      "content-type": "application/json",
      Authorization: "Bearer " + accessToken,
    };

    //lets return live data
    let postData = this.http.post(url, body, {
      headers: headers,
      observe: "body",
    });
    return postData;
  }


  getMeteringHierachy(): Observable<any> {
    let body = {
      payload: {
        userID: parseInt(localStorage.getItem("userID") as string),
      },
    };

    // If data is already in localStorage, return it as an Observable
    if (localStorage.getItem('metering_hierarchy')) {
      const cachedData = JSON.parse(localStorage.getItem('metering_hierarchy') as string);
      return of(cachedData);
    }

    // If data is not in localStorage, make the API request
    return this.apiRequest("metering/metering_hierarchy/", "", body).pipe(
      retryWhen(errors =>
        errors.pipe(
          // Retry up to 5 times with a delay of 1 seconds between attempts
          concatMap((error, i) => i < 5 ? of(error).pipe(delay(1000)) : throwError(error))
        )
      ),
      tap((res: any) => {
        // Save the response to localStorage
        if (res) {
          localStorage.setItem('metering_hierarchy', JSON.stringify(res));
        }
      }),
      catchError((error) => {
        console.error("Failed to retrieve metering hierarchy after retries:", error);
        return throwError(error);
      })
    );
  }

  getAccountHierachy(): Observable<any> {
    let body = {
      payload: {
        userID: parseInt(localStorage.getItem("userID") as string),
      },
    };

    // If data is already in localStorage, return it as an Observable
    if (localStorage.getItem('account_hierarchy')) {
      const cachedData = JSON.parse(localStorage.getItem('account_hierarchy') as string);
      return of(cachedData);
    }

    // If data is not in localStorage, make the API request
    return this.apiRequest("metering/account_hierarchy/", "", body).pipe(
      retryWhen(errors =>
        errors.pipe(
          // Retry up to 5 times with a delay of 1 seconds between attempts
          concatMap((error, i) => i < 5 ? of(error).pipe(delay(1000)) : throwError(error))
        )
      ),
      tap((res: any) => {
        // Save the response to localStorage
        if (res) {
          localStorage.setItem('account_hierarchy', JSON.stringify(res));
        }
      }),
      catchError((error) => {
        console.error("Failed to retrieve account hierarchy after retries:", error);
        return throwError(error);
      })
    );
  }

  getDevices(): Observable<any> {
    let body = {
      payload: {
        userID: parseInt(localStorage.getItem("userID") as string),
      },
    };

    // If data is already in localStorage, return it as an Observable
    if (localStorage.getItem('devices')) {
      const cachedData = JSON.parse(localStorage.getItem('devices') as string);
      return of(cachedData);
    }

    // If data is not in localStorage, make the API request
    return this.apiRequest("utilities/devices/", "", body).pipe(
      retryWhen(errors =>
        errors.pipe(
          // Retry up to 5 times with a delay of 1 seconds between attempts
          concatMap((error, i) => i < 5 ? of(error).pipe(delay(1000)) : throwError(error))
        )
      ),
      tap((res: any) => {
        // Save the response to localStorage
        if (res) {
          localStorage.setItem('devices', JSON.stringify(res));
        }
      }),
      catchError((error) => {
        console.error("Failed to retrieve devices after retries:", error);
        return throwError(error);
      })
    );
  }

}
