import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IdelTimeoutService } from '@app/services/idel-timeout.service';

@Component({
  selector: 'app-inactivity-alert',
  templateUrl: './inactivity-alert.component.html',
  styleUrls: ['./inactivity-alert.component.scss']
})
export class InactivityAlertComponent {
  @Input() title!: string;
  @Input() message!: string;
  @Input() countdown!: any;
  @Input() autoLogoutMessage!: any;

  constructor(public activeModal: NgbActiveModal , private idelTimeoutService:IdelTimeoutService) {}

  confirm(): void {
    this.activeModal.close('confirmed'); // Pass 'confirmed' result
  }

  logout(): void {
    this.activeModal.dismiss('logged_out'); // Pass 'logged_out' result
    this.idelTimeoutService.logoutAutoUser(); 
    
  }
}
