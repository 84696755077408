import { ChangeDetectionStrategy, Component, Input, OnInit, ChangeDetectorRef,
    SimpleChanges,
 } from '@angular/core';
import { Router } from '@angular/router';
// import { ModalDismissReasons, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ApiRequestService } from '@common/services/api-request.service';
import { ToastService } from '@common/services/toast.service';

@Component({
    selector: 'sbpro-dashboard-head',
    templateUrl: './dashboard-head.component.html',
    styleUrls: ['dashboard-head.component.scss'],
})
export class DashboardHeadComponent implements OnInit {
    @Input() icon!: string;
    @Input() title!: string;
    @Input() description!: string;
    @Input() showDateRange!: false;
    @Input() showSearch!: false;
    @Input() showReporting!: string;
    @Input() deviceType!: string;
    // @Input() hasConfig!: string;
    

    hasConfig:  any = "";
    deviceVersion = "";
    activeLink = "";
    activeLevel = "";
    active: any = "";
    meteringFarmID = "";
    hasMeteringSection = "no";
    hasMeteringFarm = "no";
    hasMeteringPoint = "no";
    meteringPointsArr: any = [];
    meteringSections: any = [];
    package: any = [];

    deviceManagement = "viewer";
    meteringFarmManagement = "viewer";
    meteringSectionManagement = "viewer";
    meteringPointManagement = "viewer";
    userManagement = "viewer";
    notificationManagement = "viewer";
    reportManagement = "";
    hasDevices = false;
    pmLevel: any = "";

    constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private toastService: ToastService,  private router: Router,
        private route: Router, private apiRequest: ApiRequestService,) {}
    ngOnInit() {

        if(this.showReporting != "no" && this.showReporting != "yes"){
            this.showReporting = "no";
        }
        let permissions = JSON.parse(localStorage.getItem("permissions") as string);
        console.log(permissions);
        this.meteringFarmManagement = permissions.meteringFarmManagement;
        this.meteringSectionManagement = permissions.meteringSectionManagement;
        this.meteringPointManagement = permissions.meteringPointManagement;
        this.deviceManagement = permissions.deviceManagement;
        this.userManagement = permissions.userManagement;
        this.notificationManagement = permissions.notificationManagement;
        this.reportManagement = permissions.reportManagement;

        if(localStorage.getItem("deviceID")){
            this.hasDevices = true;
        }

        this.package = JSON.parse(localStorage.getItem("package") as string);

        this.pmLevel = localStorage.getItem("pmLevel");
        this.active = localStorage.getItem("active");
                
        this.activeLink = localStorage.getItem("activeLink") as string;
        this.activeLevel = localStorage.getItem("activeLevel") as string;
        this.changeDetectorRef.detectChanges();
        setTimeout(() =>{
            if(localStorage.getItem("hasMeteringFarm")) this.hasMeteringFarm = localStorage.getItem("hasMeteringFarm") as string;
            if(localStorage.getItem("hasMeteringSection")) this.hasMeteringSection = localStorage.getItem("hasMeteringSection") as string;
            if(localStorage.getItem("hasMeteringPoint")) this.hasMeteringPoint = localStorage.getItem("hasMeteringPoint") as string;
    
            this.activeLink = localStorage.getItem("activeLink") as string;
            //this.activeLevel = localStorage.getItem("activeLevel") as string;
            this.deviceType = localStorage.getItem("deviceType") as string;
            //this.deviceVersion = localStorage.getItem("deviceVersion") as string;
            this.changeDetectorRef.detectChanges();
            let devices: any = {}
            if(localStorage.getItem("devices")) {
                devices = JSON.parse(localStorage.getItem("devices") as string);
                if(devices.data.total > 0){
                    this.hasDevices = true;
                }
            }
            else{
                this.getDevices()
            }

           
        },3000)
    }
    


    ngAfterViewInit() {

        setTimeout(() =>{
            this.getMeteringHierachy();

            if(localStorage.getItem("hasMeteringFarm")) this.hasMeteringFarm = localStorage.getItem("hasMeteringFarm") as string;
            if(localStorage.getItem("hasMeteringSection")) this.hasMeteringSection = localStorage.getItem("hasMeteringSection") as string;
            if(localStorage.getItem("hasMeteringPoint")) this.hasMeteringPoint = localStorage.getItem("hasMeteringPoint") as string;
    
            this.activeLink = localStorage.getItem("activeLink") as string;
            //this.activeLevel = localStorage.getItem("activeLevel") as string;
            this.deviceType = localStorage.getItem("deviceType") as string;
            //this.deviceVersion = localStorage.getItem("deviceVersion") as string;
            this.changeDetectorRef.detectChanges();
        },3000)

        setTimeout(() =>{
            this.deviceType = localStorage.getItem("deviceType") as string;
            this.hasConfig = localStorage.getItem("hasConfig") as string;
            this.deviceVersion = localStorage.getItem("deviceVersion") as string;
            this.activeLevel = localStorage.getItem("activeLevel") as string;
            this.changeDetectorRef.detectChanges();
        },3000)
       
        this.changeDetectorRef.detectChanges();
    }

    


    getMeteringHierachy() {
        let body = {
            payload: {
                userID: parseInt(localStorage.getItem("userID") as string),
            },
        };

        let jsonF = "metering_hierarchy.json";
        
        if(localStorage.getItem("pmLevel") == "2"){
            jsonF = "metering_hierarchy_2.json";
        }
        else if(localStorage.getItem("pmLevel") == "3" || localStorage.getItem("pmLevel") == "3") {
            jsonF = "metering_hierarchy_3.json";
        }
        var res: any = JSON.parse(localStorage.getItem('metering_hierarchy') as string);

        // this.apiRequest.apiRequest("metering/account_hierarchy/", jsonF, body)
        //     .subscribe((res) => {
                if (res) {
                    if(res.data.meteringFarms){
                        if(res.data.meteringFarms.length > 0){
                            localStorage.setItem("hasMeteringFarm", "yes"); 
                          }
                        //this.meteringFarms = res.data.meteringFarms;
                        for (var i = 0; i < res.data.meteringFarms.length; i++) {
                            for (var j = 0; j < res.data.meteringFarms[i].meteringSections.length; j++) {
                                this.meteringSections.push(res.data.meteringFarms[i].meteringSections[j]);

                                this.meteringPointsArr[
                                    res.data.meteringFarms[i].meteringSections[j].meteringSectionID
                                ] = res.data.meteringFarms[i].meteringSections[j].meteringPoints;

                            }
                        }
                    }
                    else if(res.data.meteringSections){
                        //this.meteringSections = res.data.meteringSections;
                        if (res.data.meteringSections.length > 0) {
                            localStorage.setItem("hasMeteringSection", "yes");
                          }
                        for (var j = 0; j < res.data.meteringSections.length; j++) {
                            this.meteringSections.push(res.data.meteringSections[j]);
                            this.meteringPointsArr[
                                res.data.meteringSections[j].meteringSectionID
                            ] = res.data.meteringSections[j].meteringPoints;
                            //this.meteringPoints = res.data.meteringSections[j].meteringPoints;
                        }
                    }
                    else if(res.data.meteringPoints){
                        if (res.data.meteringPoints.length > 0) {
                            localStorage.setItem("hasMeteringPoint", "yes");
                            localStorage.setItem("hasMeteringSection", "yes");
                          }
                        //this.meteringPoints = res.data.meteringPoints;
                        this.meteringPointsArr[0] = res.data.meteringPoints;

                    }
                }
                this.changeDetectorRef.detectChanges();
           // });
    }


    async loadPage(pageOption: any){
        const defaultOptions = {
            autohide: true,
            delay: 8000,
        };

        localStorage.setItem("page", pageOption);
        this.activeLevel = "metering";

        

        if(parseInt(this.pmLevel) > 3 && ( pageOption == "reporting" || pageOption == "deviceSelect")){
            this.toastService.show("No Metering Section Found", "To do this action you need to add a Metering Section", { headerClasses: 'bg-warning text-light', ...defaultOptions });
        }
        else{
            //validations
            if(pageOption == "user") {
                   
                if(this.package.name == "essential" && this.package.features["user-management"].accounts >= 3){
                    this.toastService.show("Pro Feature", "You have reached you maximum user accounts.\n Please upgrade your package to add more user accounts.", { headerClasses: 'bg-secondary text-light', ...defaultOptions });
                    return false;
                }
                else{
                    localStorage.setItem("userAction", "add_account");

                    window.location.assign("/dashboard/metering/user")

                }
               
            }
            if(pageOption == "googleSheets") {
                if (localStorage.getItem("googleSheetUrl") && localStorage.getItem("googleSheetUrl") != ""){
                    window.open(localStorage.getItem("googleSheetUrl") as string);
                }
                else{
                    if(localStorage.getItem("active") != "reporting"){
                        this.toastService.show("Google Sheets", "The Google Sheet data is not available. Please go to Reporting and select a metering a Metering Section", { headerClasses: 'bg-warning text-light', ...defaultOptions });
                    }
                    else{
                        this.toastService.show("Google Sheets", "The Google Sheet data is not yet available.", { headerClasses: 'bg-warning text-light', ...defaultOptions });
                    }
                }
                return false;
            }

            this.route.navigateByUrl("/dashboard/metering");
            setTimeout(() =>{
                if(pageOption == "deviceSelect") {
                    localStorage.setItem("active", "dashboard");

                    localStorage.setItem("meterReplace", "no");
                    localStorage.setItem("deviceAction", "add");
                    localStorage.setItem("deviceID", "");
                    localStorage.setItem("deviceVersion", "");
                    this.activeLevel = "device";
                    localStorage.setItem("showReporting", "no");
                    window.location.assign("/dashboard/metering/deviceSelect")

                    // this.route.navigateByUrl("/dashboard/metering/deviceSelect");
                }
                if(pageOption == "meteringFarmForm") {
                    localStorage.setItem("active", "dashboard");

                    localStorage.setItem("meteringSectionID", "");
                    localStorage.setItem("meteringFarmAction", "add_metering_farm");
                    //this.route.navigateByUrl("/dashboard/metering/meteringSection");
                    window.location.assign("/dashboard/metering/meteringFarmForm")

                }
                if(pageOption == "meteringSection") {
                    localStorage.setItem("active", "dashboard");

                    localStorage.setItem("meteringSectionID", "");
                    localStorage.setItem("meteringSectionAction", "add_metering_section");
                    //this.route.navigateByUrl("/dashboard/metering/meteringSection");
                    //window.location.assign("/dashboard/metering/meteringSection");
                    this.router.navigate(["/dashboard/metering/meteringSection"]);

                }
                if(pageOption == "meteringPoint") {
                    localStorage.setItem("active", "dashboard");

                    localStorage.setItem("meteringPointID", "");
                    localStorage.setItem("meteringPointAction", "add_metering_point");
                    //this.route.navigateByUrl("/dashboard/metering/meteringPoint");
                    window.location.assign("/dashboard/metering/meteringPoint")

                }
                if(pageOption == "user") {
                    localStorage.setItem("userAction", "add_account");

                    if(this.package.name == "essential" && this.package.features["user-management"].accounts >= 3){
                        this.toastService.show("No Metering Section Found", "You have reached max users for your current package", { headerClasses: 'bg-warning text-light', ...defaultOptions });
                    }
                    else {
                        localStorage.setItem("active", "users");
                        //this.route.navigateByUrl("/dashboard/metering/user");
                        window.location.assign("/dashboard/metering/user")
                    }
                   
                }
                if(pageOption == "notifications") {
                    localStorage.setItem("active", "notifications");
                    localStorage.setItem("notificationAction", "add_notification");
                    //this.route.navigateByUrl("/dashboard/metering/notifications");
                    window.location.assign("/dashboard/notifications/notifications")
                }
                if(pageOption == "reporting") {
                    localStorage.setItem("active", "reporting");
                    //this.route.navigateByUrl("/dashboard/reporting");
                     window.location.assign("/dashboard/reporting")
                }
            },500);
        }
        
        
    }

    async loadPageEdit(){
        const defaultOptions = {
            autohide: true,
            delay: 8000,
        };
        
        let pageOption = localStorage.getItem("activeLevel");
        
        if(pageOption == "device") {
            this.route.navigateByUrl("/dashboard/metering/device");
        }
        if(pageOption == "meteringSection") {
            this.route.navigateByUrl("/dashboard/metering/meteringSection");
        }
        if(pageOption == "meteringPoint") {
            this.route.navigateByUrl("/dashboard/metering/meteringPoint");
        }
        if(pageOption == "user") {
            this.route.navigateByUrl("/dashboard/metering/user");
        }
        
        
        
    }


    async loadPageUsers(){
        let pageOption = localStorage.getItem("activeLevel");
        
        this.route.navigateByUrl("/dashboard/metering/users");
    }

    async loadPageNotifications(){
        let pageOption = localStorage.getItem("activeLevel");
        
        this.route.navigateByUrl("/dashboard/metering/notifications");
    }

    async firmwareSettings(){
        let pageOption = localStorage.getItem("firmwareSettings");
        
        this.route.navigateByUrl("/dashboard/metering/firmwareSettings");
    }

    async loadPageReporting(){
        let pageOption = localStorage.getItem("reporting");
        
        this.route.navigateByUrl("/dashboard/reporting/mainReport");
    }


    async loadModal(modalOption: any){
        if(modalOption == "delete") {
            this.route.navigateByUrl("/dashboard/metering/delete");
        }
    }


    getDevices() {
       
    
        let body = {
          payload: {
            userID: parseInt(localStorage.getItem("userID") as string),
          },
        };
    
        this.apiRequest.apiRequest("utilities/devices/", "devices.json", body)
          .subscribe((res) => {
                if (res.success) {
              console.log('Save json devices');
                localStorage.setItem('devices', JSON.stringify((res)));
              }
                if(res.data.total > 0){
                    this.hasDevices = true;
                }
            const defaultOptions = {
              autohide: true,
              delay: 5000,
            };
    
            this.changeDetectorRef.detectChanges();
          },
            (error: any) => {
              const defaultOptions = {
                autohide: true,
                delay: 5000,
              };
    
              let message = "";
              for (var i = 0; i < error.error.data.length; i++) {
                message += error.error.data[i] + "\n";
              }
              this.toastService.show("Device Search", message, { headerClasses: 'bg-danger text-light', ...defaultOptions });
    
            });
    
    }

    navigatePage(pageOption: any) {
        const defaultOptions = {
          autohide: true,
          delay: 10000,
        };
        this.package = JSON.parse(localStorage.getItem("package") as string);
    
        let landingPlat = localStorage.getItem("landingPlat") as string;
    
        localStorage.setItem("pageOption", pageOption);
    
        let licenseExpiryDate = new Date(
          localStorage.getItem("licenseExpiryDate") as string
        );
    
        let today = new Date();
        licenseExpiryDate.setDate(licenseExpiryDate.getDate() + 7); //give 7 days for billing
    
        if (this.package.name == "essential" && licenseExpiryDate < today) {
          localStorage.setItem("licenseExpiry", "yes");
          // this.ngxService.stop();
    
          return false;
        } else if (localStorage.getItem("resetPassword") == "yes") {
          //this.router.navigate(['/auth/passwordReset']);
          window.location.assign("/auth/passwordReset");
        } else {
          //this.router.navigate([pageOption]);
          if (pageOption == "/dashboard" && parseInt(landingPlat) > 9) {
            localStorage.setItem("active", "reporting");
            localStorage.setItem("reload", "yes");
            if (landingPlat == "10")
              localStorage.setItem("reportType", "trendAnalysis");
            if (landingPlat == "11")
              localStorage.setItem("reportType", "billingReport");
            if (landingPlat == "12")
              localStorage.setItem("reportType", "meterComparison"); //localStorage.setItem("reportType", "meteringAnalysis");
            if (landingPlat == "13")
              localStorage.setItem("reportType", "billingInvoice");
            // if(landingPlat == "14") localStorage.setItem("reportType","efficiencyReport");
            if (landingPlat == "15")
              localStorage.setItem("reportType", "organisationAnalysis");
            if (landingPlat == "16")
              localStorage.setItem("reportType", "meterComparison");
            if (landingPlat == "17")
              localStorage.setItem("reportType", "actionTracker");
            // if(landingPlat == "18") localStorage.setItem("reportType","heatRecovery");
            if (landingPlat == "19")
              localStorage.setItem("reportType", "customReport");
            if (landingPlat == "20")
              localStorage.setItem("reportType", "wastageReport");
    
            //this.navigationService.router.navigateByUrl("/dashboard/reporting");
            window.location.assign("/dashboard/reporting");
          } else {
            if (landingPlat == "4")
              pageOption = "/dashboard/diagnostics/geolocation";
    
            window.location.assign(pageOption);
          }
        }
      }
}
